import React, { useEffect, useState } from "react";
import { Card, Form, Tab, Tabs } from "react-bootstrap";
import { MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  CardBody,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton
} from "./styles";
import { getHeadersDocuments } from "../../../../services/form";
import { useHistory, useParams } from "react-router-dom";
import { getSelectorsByFlow } from "../../../../services/field";
import { getDocumentFormById, postDocumentForm, putDocumentForm } from "../../../../services/flow";
import MyEditor from "../../../../Components/MyEditor";
import Loading from '../../../../Components/Loading';
import { useStateValue } from "../../../../providers/StateProvider";
import { actionTypes } from "../../../../store/reducer";

const ConfigurationDocument = () => {
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const [headers, setHeaders] = useState([]);
  const [selectors, setSelectors] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [descricao, setDescricao] = useState<string>("");
  const [documentForm, setDocumentForm] = useState(null);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [conteudoEditavel, setConteudoEditavel] = useState<null | boolean>(true);
  const [assinadoSistema, setAssinadoSistema] = useState<null | boolean>(false);
  const [assunto, setAssunto] = useState<string>('');
  const [tipoDocumento, setTipoDocumento] = useState<string>('');
  const [obrigatorioAssinatura, setObrigatorioAssinatura] = useState<null | boolean>(false);
  const [active, setActive] = useState<null | boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { flow_slug, orgao_id, id_document } = useParams<{ flow_slug: string, orgao_id: string, id_document?: string }>();

  const handleCloseCancel = () => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Cancelado',
        message: 'Documento não foi criado.',
      },
    });
  }

  const alertSave = () => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'success-alt',
        title: 'Sucesso',
        message: 'Documento foi salvo com sucesso.',
      },
    });
  }

  const handleSave = async (e) => {
    try {
      if (descricao) {
        setLoading(true);
        setShowMessageError(false);

        if (id_document) {
          await putDocumentForm(
            id_document,
            flow_slug,
            selectedHeader.id,
            assunto,
            descricao,
            tipoDocumento,
            conteudoEditavel,
            obrigatorioAssinatura ? false : assinadoSistema,
            obrigatorioAssinatura,
            active
          );
        } else {
          await postDocumentForm(
            flow_slug,
            selectedHeader.id,
            descricao,
            assunto,
            tipoDocumento,
            conteudoEditavel,
            obrigatorioAssinatura ? false : assinadoSistema,
            obrigatorioAssinatura
          );
        }
        alertSave();
        history.push(`/informacoes/${flow_slug}`);

      } else {
        setShowMessageError(true);
      }
    } catch (err) {
      handleCloseCancel();
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const getAllApis = async () => {
    try {
      setLoading(true);
      const [selectores, headersapi]: any = await Promise.all([
        getSelectorsByFlow(flow_slug, undefined, true),
        getHeadersDocuments(orgao_id),
      ]);
      setSelectors(selectores.data);
      setHeaders(headersapi.data.results);

    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    if (documentForm) {
      const findHeader = headers.find((header) => header.id === documentForm?.cabecalho?.id);
      setSelectedHeader(findHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers])

  useEffect(() => {
    getAllApis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDocument = async () => {
    const { data } = await getDocumentFormById(flow_slug, id_document);
    setDocumentForm(data);
  }

  useEffect(() => {
    if (id_document) {
      handleGetDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_document]);

  useEffect(() => {
    if (documentForm) {
      setDescricao(documentForm.conteudo);
      setAssunto(documentForm.assunto);
      setTipoDocumento(documentForm.tipo)
      setAssinadoSistema(documentForm.sistema_assina);
      setConteudoEditavel(documentForm.conteudo_editavel);
      setObrigatorioAssinatura(documentForm.usuario_assina);
      setActive(documentForm.ativo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentForm]);


  return (
    <ContainerMain>
      <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>Configurar Documento</Title>
          </CardHeader>

          <Tabs defaultActiveKey="edit" className="mb-3">
            <Tab eventKey="edit" title="Editar">
              <>
                <CardBody>
                  <CustomRow>
                    <Form.Group className="mb-3">
                      <FormLabel>Cabeçalho</FormLabel>
                      <Form.Select value={selectedHeader?.id} onChange={(e) => {
                        const findHeader = headers.find((header) => header.id === e.target.value);
                        setSelectedHeader(findHeader);
                      }} disabled={documentForm && !documentForm?.conteudo_editavel}>
                        <option value="none">Selecione</option>
                        {headers.map((header) => (
                          <option selected={header.id === selectedHeader?.id} value={header.id}>{header.titulo}</option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-muted">
                        Informe o cabeçalho do documento
                      </Form.Text>
                    </Form.Group>
                  </CustomRow>
                  <CustomRow>
                    <Form.Group className="mb-3">
                      <FormLabel>Tipo de Documento</FormLabel>
                      <Form.Select value={tipoDocumento} onChange={(e) => {
                        setTipoDocumento(e.target.value)
                      }} >
                        <option value="none">Selecione</option>
                        <option selected={tipoDocumento === 'Resposta'} value="Resposta">Resposta</option>
                        <option selected={tipoDocumento === 'Solicitação'} value="Solicitação">Solicitação</option>
                      </Form.Select>
                      <Form.Text className="text-muted">
                        Informe o tipo de documento
                      </Form.Text>
                    </Form.Group>
                  </CustomRow>

                  {tipoDocumento === 'Resposta' && (
                    <CustomRow>
                      <Form.Group className="mb-3" >
                        <FormLabel required>Obrigatório assinatura do servidor</FormLabel>
                        <Form.Select onChange={(e) => {
                          setObrigatorioAssinatura(e.target.value === 'true');
                        }}>
                          <option selected={obrigatorioAssinatura === true} value="true">Sim</option>
                          <option selected={obrigatorioAssinatura === false} value="false">Não</option>
                        </Form.Select>
                        <Form.Text className="text-muted">
                          Caso o documento exija a assinatura do servidor, selecione <strong>SIM</strong>
                        </Form.Text>
                      </Form.Group>
                    </CustomRow>
                  )}


                  <CustomRow>
                    <Form.Group className="mb-3" >
                      <FormLabel required>Assunto</FormLabel>
                      <Form.Control
                        required
                        value={assunto}
                        onChange={(e) => setAssunto(e.target.value)}
                      >
                      </Form.Control>
                      <Form.Text className="text-muted">
                        Informe o assunto do documento
                      </Form.Text>
                    </Form.Group>
                  </CustomRow>

                  <CustomRow>
                    <Form.Group className="mb-3">

                      {documentForm && !documentForm?.conteudo_editavel ? (<></>) : (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FormLabel>Conteúdo</FormLabel>
                        {/* <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            {"{  }"} Seletores
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {selectors.map((selector) => (
                              <Dropdown.Item
                                onClick={() => {
                                  setDescricao(descricao + "<b>${" + `${selector.id} - ${selector.titulo}` + "}</b>")
                                  // console.log(descricao);
                                }}
                                href=""
                              >{`${selector?.formulario.titulo} / ${selector.titulo}`}</Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </div>)}
                      {loading ? (<>
                        <Loading /></>) : (<>
                          {selectors.length > 0 && <MyEditor
                            value={descricao}
                            setValue={setDescricao}
                            modeEdit="full"
                            addPlugins={["print", "preview"]}
                            addConf={{
                              height: 600
                            }}
                            disabled={documentForm && !documentForm?.conteudo_editavel}
                            selectors={selectors}
                          />}

                          {showMessageError && !descricao && (
                            <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>
                              Este campo não pode ser vazio.
                            </p>
                          )}
                        </>)}
                      <Form.Text className="text-muted">
                        Informe o conteúdo do documento
                      </Form.Text>
                    </Form.Group>
                  </CustomRow>

                  <CustomRow>
                    <Form.Group className="mb-3" >
                      <FormLabel required>Conteúdo Editável após ser salvo</FormLabel>
                      <Form.Select onChange={(e) => {
                        setConteudoEditavel(e.target.value === 'true');
                      }}>
                        <option selected={conteudoEditavel === true} value="true">Sim</option>
                        <option selected={conteudoEditavel === false} value="false">Não</option>
                      </Form.Select>
                      <Form.Text className="text-muted">
                        Informe se o conteúdo do documento pode ser editável após ser salvo
                      </Form.Text>
                    </Form.Group>
                  </CustomRow>
                  {!obrigatorioAssinatura && (
                    <CustomRow>
                      <Form.Group className="mb-3" >
                        <FormLabel required>Assinado automaticamente pelo sistema</FormLabel>
                        <Form.Select onChange={(e) => {
                          setAssinadoSistema(e.target.value === 'true');
                        }}>

                          <option selected={assinadoSistema === true} value="true">Sim</option>
                          <option selected={assinadoSistema === false} value="false">Não</option>
                        </Form.Select>
                        <Form.Text className="text-muted">
                          Informe se o documento é assinado automaticamente pelo sistema
                        </Form.Text>
                      </Form.Group>
                    </CustomRow>
                  )}
                  <CustomRow>
                    <Form.Group className="mb-3" >
                      <FormLabel required>Documento está ativo no fluxo?</FormLabel>
                      <Form.Select onChange={(e) => {
                        setActive(e.target.value === 'true');
                      }}>

                        <option selected={active === true} value="true">Sim</option>
                        <option selected={active === false} value="false">Não</option>
                      </Form.Select>
                      <Form.Text className="text-muted">
                        Informe se o documento estará ativo na solicitação
                      </Form.Text>
                    </Form.Group>
                  </CustomRow>
                </CardBody>
                <CardFooterCustom>
                  <ActionButton
                    className="button"
                    style={{ backgroundColor: "#fff", color: "#506176" }}
                    onClick={() => history.push(`/informacoes/${flow_slug}`)}
                  >
                    <span style={{ marginLeft: 8 }}>
                      Cancelar
                    </span>
                  </ActionButton>
                  {loading ? (
                    <Loading />
                  ) : (
                    <ActionButton
                      style={{ backgroundColor: '#27AB6E' }}
                      type='submit'
                      onClick={handleSave}
                    >

                      <span style={{ marginLeft: 8 }}>
                        {documentForm && documentForm?.length >= 1 ? 'Atualizar' : 'Salvar'}
                      </span>
                    </ActionButton>
                  )}
                </CardFooterCustom>
              </>
            </Tab>
            <Tab eventKey="preview" title="Prévia">
              <CardBody>
                <MyEditor
                  value={''.concat(selectedHeader ? selectedHeader?.conteudo : '').concat(descricao ?? '')}
                  disabled={true}
                  addConf={{
                    height: 600,
                  }}
                />
              </CardBody>
            </Tab>
          </Tabs>
        </Card>
      </form>
    </ContainerMain>
  );
};

export default ConfigurationDocument;
